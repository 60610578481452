<template>
    <div class="mt-n6">
        <v-container class="py-16">
            <v-row>
                <v-col md="7" cols="12">
                    <div class="d-block mt-10 mb-10" style="text-align: left">
                        <label class="d-block yellow--text text--darken-3 font-weight-bold">ติดต่อเรา</label>
                        <hr class="rounded" />
                        <label class="d-block white--text font-weight-bold mb-2">{{ contact.companyName }}</label>
                        <label class="d-block grey--text text--lighten-2">สำนักงานใหญ่: {{ contact.address }}</label>
                        <!-- <label class="d-block grey--text text--lighten-2">เขตประเวศ กรุงเทพมหานครฯ 10250</label> -->
                        <label class="d-block grey--text text--lighten-2">อีเมล: {{ contact.email }}</label>
                        <label class="d-block grey--text text--lighten-2">โทร: {{ contact.mobile }}</label>
                    </div>
                    <v-row>
                        <v-col cols="6" style="text-align: left">
                            <label class="d-block white--text font-weight-bold mb-2">สาขาทั้งหมดของเรา</label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-for="l of contact.branches" :key="l.name" cols="12" md="6" style="text-align: left">
                            <label class="d-block yellow--text text--darken-3 font-weight-bold mb-2">{{ l.name
                                }}</label>
                            <label class="grey--text text--lighten-2">{{ l.address }} </label>
                            <label class="d-block grey--text text--lighten-2">โทร {{ l.mobile ? l.mobile : '-' }}
                            </label>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col md="5" cols="12">
                    <v-img class="" width="100%" height="100%"
                        src="/images/map.svg"></v-img>
                </v-col>
            </v-row>
        </v-container>

        <!-- Util conponents -->
        <dialog-error ref="dialogError" :topic="'Error'" :text="errorMessage" />
    </div>
</template>

<script>
import DialogError from '../components/DialogError.vue';
import axios from 'axios';

export default {
    data: () => ({
        location: [
            { name: 'สีลม', address: '32/7-8 ถนนสุรศักดิ์ แขวงสีลม เขตบางรัก กรุงเทพมหานคร 10500' },
            { name: 'หาดใหญ่', address: '4/17-18 ถนนธรรมนูญวิถี ตำบลหาดใหญ่ อำเภอหาดใหญ่ จังหวัดสงขลา 90100' },
            { name: 'วังบูรพา', address: '857 ถนนมหาไชย แขวงวังบูรพาภิรมย์ เขตพระนคร กรุงเทพมหานคร 10200' },
            { name: 'สุราษฏร์ธานี', address: '191/4, 191/5 ถนนชนเกษม ตำบลตราด อำเภอเมือง จังหวัดสุราษฏร์ธานี 84000' },
            { name: 'เชียงใหม่', address: '8/14 ถนนเชียงใหม่-ลำปาง ตำบลวัดเกต อำเภอเมือง จังหวัดเชียงใหม่ 50000' },
            { name: 'นครราชสีมา', address: '293 ถนนพิบูลละเอียด ตำบลในเมือง อำเภอเมืองนครราชสีมา จังหวัดนครราชสีมา 30000' },
            { name: 'พิษณุโลก', address: '198/17-18 ถนนเอกาทศรฐ ตำบลในเมือง อำเภอเมือง จังหวัดพิษณุโลก 65000' }
        ],
        contact: {
            branches: []
        },
        errorMessage: ''
    }),
    async mounted(){
        try{
            const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents/contact-us');
            this.contact = response.data;
        }catch(error){
            this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
            this.$refs.dialogError.show();
        }
    },
    components: {
        DialogError
    }
};
</script>


<style scoped>
  hr.rounded {
    border-top: 1px solid #C49C4F;
    border-radius: 5px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
</style>